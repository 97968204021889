/* eslint-disable indent */
import React, { useState, useEffect, useMemo } from 'react';
import {
	CREATE_EDIT_PORTFOLIO_TYPE,
	PORTFOLIO_TRACKER_TAB_NAMES,
	PORTFOLIO_TRACKER_KEYS,
} from '../portfolio-constants';
import {
	DEFAULT_PORTFOLIO_TRACKER_SORTING_INFO,
	FUNDAMNETALS_PORTFOLIO_TRACKER_HEADER,
	IPortfolioSortingInfo,
	PERFORMANCE_PORTFOLIO_TRACKER_HEADER,
	PORTFOLIO_TRAKER_KEYS as PORTFOLIO_CONSTANT,
	PORTFOLIO_TRACKER_PREFERENCE_KEY,
	QUOTES_PORTFOLIO_TRACKER_HEADER,
} from '../portfolio-tracker/portfolio-tracker-constants';
import './portfolio-tracker.scss';
import {
	Tabs,
	Loader,
	MessageBox,
	MessageBoxStateType,
	Heading,
	LabelLink,
	Label,
	SymbolDetails,
} from '../../../../@core-ui';
import {
	PortfolioHoldingsCrud,
	PortfolioTransactionsCrud,
	getQuotes,
	getPortfoliosInfo,
	getNews,
	fetchXrefDataInChunks,
	getUserPreferences,
	setUserPreferences,
} from '../../../../services';
import { checkIfAPIError, getStockAssetClass } from '../portfolio-utils';
import {
	addQBIDArgument,
	getComparisonDates,
	getDeviceType,
	handleEnterKeyPress,
	sortByKey,
} from '../../../../utilities/utils';
import {
	IXrefResponseItem,
	IUnrealizedGain,
	IGetTransactionItemResponse,
	IGetHoldingsResponse,
	IGetTransactionsResponse,
	IPortfolioAnalysisResponse,
} from '../../../../types/interfaces';
import { formatNumber, TEXT_NULL_VALUE } from '../../../../utilities/formatter';
import {
	formatDateTime,
	DATE_FORMATS,
} from '../../../../utilities/date-time-formatter';
import {
	IHoldingsWithTransactions,
	ITableHolding,
	IAssetAllocation,
	QuotesPortfolioConstants,
	FundamentalsPortfolioConstants,
	PerformancePortfolioConstants,
	PortfolioTrackerMessage,
	LegendControls,
	STOCKS_TYPE_COLOR_MAPPING,
} from './portfolio-tracker-constants';
import {
	DEFAULT_NEWS_INPUTS,
	E_DeviceType,
	SORTING_DIRECTION,
} from '../../../../constants/appConstants';
import { ArrowRightIcon } from './../../../../assets/Icons';
import PortfolioTrackerTable from './portfolio-tracker-table/portfolio-tracker-table';
import PortfolioTrackerChart from './portfolio-tracker-chart/portfolio-tracker-chart';

import { usePortfolioTabContext } from '../portfolio-container';
import { ISortingInfo } from '../../../../@core-ui/Table/table';

type PortfolioTrackerProps = {
	data: CREATE_EDIT_PORTFOLIO_TYPE;
	onLoad?: () => void;
};

type ContainerState = {
	isLoading?: boolean;
	noData?: boolean;
	alert?: {
		showAlert: boolean;
		content: string;
		state: MessageBoxStateType;
	};
};

const PortfolioTracker: React.FC<PortfolioTrackerProps> = ({
	data,
	onLoad,
}) => {
	const setActiveTab = usePortfolioTabContext()?.setActiveTab;
	const activeTab = new URLSearchParams(window.location.search).get(
		'activeTab',
	);
	const isMobile = getDeviceType() === E_DeviceType.Mobile;
	const [activeSelectedTab, setActiveSelectedTab] = useState(
		activeTab ?? PORTFOLIO_TRACKER_TAB_NAMES.Quotes,
	);
	const [containerState, setContainerState] = useState<ContainerState>({
		isLoading: true,
		noData: false,
		alert: {
			showAlert: false,
			content: '',
			state: MessageBoxStateType.Success,
		},
	});
	const [isExportData, setIsExportData] = useState(false);
	const [loadDivAndRatiosData, setLoadDivAndRatiosData] = useState(true);
	const holdingInstance = new PortfolioHoldingsCrud(data?.id ?? '');
	const transactionInstance = new PortfolioTransactionsCrud(data?.id ?? '');
	const [holdingsData, setHoldingsData] = useState<IHoldingsWithTransactions>({
		name: '',
		id: '',
		holdings: [],
	});
	const [assetAllocationData, setAssetAllocationData] =
		useState<IAssetAllocation | null>(null);
	const [controls, setControls] = useState(LegendControls.Show);
	const [showLegend, setShowLegend] = useState(!isMobile);
	const [sortingInfo, setSortingInfo] = useState<IPortfolioSortingInfo>();

	const assetAllocationDataMemo = useMemo(() => {
		return assetAllocationData;
	}, [assetAllocationData]);

	useEffect(() => {
		sortPortfolioData(holdingsData, sortingInfo);
	}, [activeSelectedTab]);

	useEffect(() => {
		setTimeout(() => {
			!containerState.isLoading && onLoad?.();
		}, 2000);
	}, [containerState]);

	useEffect(() => {
		setLoadDivAndRatiosData(true);
	}, [holdingsData.id]);

	useEffect(() => {
		fetchData();
	}, [data]);

	const fetchData = async () => {
		getHoldings();
	};

	const getSortingInfo = async () => {
		const [
			quotePrefrenceResponse,
			performancePrefrenceResponse,
			fundamentalsPrefrenceResponse,
		] = await Promise.all([
			getUserPreferences(PORTFOLIO_TRACKER_PREFERENCE_KEY.Quotes),
			getUserPreferences(PORTFOLIO_TRACKER_PREFERENCE_KEY.Performance),
			getUserPreferences(PORTFOLIO_TRACKER_PREFERENCE_KEY.Fundamentals),
		]);
		let quotesSortingInfo: ISortingInfo =
			DEFAULT_PORTFOLIO_TRACKER_SORTING_INFO[
				PORTFOLIO_TRACKER_TAB_NAMES.Quotes
			];
		let performanceSortingInfo: ISortingInfo =
			DEFAULT_PORTFOLIO_TRACKER_SORTING_INFO[
				PORTFOLIO_TRACKER_TAB_NAMES.Performance
			];
		let fundamentalsSortingInfo: ISortingInfo =
			DEFAULT_PORTFOLIO_TRACKER_SORTING_INFO[
				PORTFOLIO_TRACKER_TAB_NAMES.Fundamentals
			];
		if (quotePrefrenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(
				quotePrefrenceResponse.data?.data?.value,
			);
			if (preferenceValue) {
				const parsePreference = JSON.parse(preferenceValue);
				quotesSortingInfo = {
					sortedColumn: parsePreference?.sortedColumn,
					sortDir: parsePreference?.sortDir,
				};
			}
		}
		if (performancePrefrenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(
				performancePrefrenceResponse.data?.data?.value,
			);
			if (preferenceValue) {
				const parsePreference = JSON.parse(preferenceValue);
				performanceSortingInfo = {
					sortedColumn: parsePreference?.sortedColumn,
					sortDir: parsePreference?.sortDir,
				};
			}
		}
		if (fundamentalsPrefrenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(
				fundamentalsPrefrenceResponse.data?.data?.value,
			);
			if (preferenceValue) {
				const parsePreference = JSON.parse(preferenceValue);
				fundamentalsSortingInfo = {
					sortedColumn: parsePreference?.sortedColumn,
					sortDir: parsePreference?.sortDir,
				};
			}
		}
		const sortingPrefrences: IPortfolioSortingInfo = {
			Quotes: quotesSortingInfo,
			Performance: performanceSortingInfo,
			Fundamentals: fundamentalsSortingInfo,
		};
		setSortingInfo(sortingPrefrences);
		return sortingPrefrences;
	};

	function hideLegendHandler() {
		setShowLegend(false);
		setControls(LegendControls.Show);
	}

	function showLegendHandler() {
		setShowLegend(true);
		setControls(LegendControls.Hide);
	}

	const logAPIError = () => {
		setContainerState({
			noData: false,
			isLoading: false,
			alert: {
				showAlert: true,
				content: PortfolioTrackerMessage.GenericError,
				state: MessageBoxStateType.Fail,
			},
		});
	};

	const buildNewsInputs = (bridgeSymbol: string) => {
		const { todaysDate } = getComparisonDates();
		const newsInputs: any = { ...DEFAULT_NEWS_INPUTS };
		newsInputs.limit = 1;
		newsInputs.arguments = [
			addQBIDArgument('BridgeSymbols', [bridgeSymbol]),
			addQBIDArgument('DocumentDate', [todaysDate], 'GreaterThanEqualTo'),
		];
		return newsInputs;
	};

	const getUnrealizedGainIndex = (
		unrealizedGains: IUnrealizedGain[],
		inceptionDate: string,
	) => {
		const {
			todaysDate,
			oneMonthAgo,
			threeMonthsAgo,
			sixMonthsAgo,
			oneYearAgo,
			yesterdayDate,
		} = getComparisonDates();

		const datesToCompare = [
			todaysDate,
			oneMonthAgo,
			threeMonthsAgo,
			sixMonthsAgo,
			oneYearAgo,
			yesterdayDate,
		];
		let beginDate = '';
		if (inceptionDate === todaysDate) {
			beginDate = yesterdayDate;
		} else {
			beginDate = datesToCompare.includes(inceptionDate)
				? inceptionDate
				: (unrealizedGains.find(
						(gain) => !datesToCompare.includes(gain.beginDate),
					)?.beginDate ?? '');
		}
		return unrealizedGains.findIndex(
			(gain) => gain.beginDate === beginDate && gain.endDate === todaysDate,
		);
	};

	const getDatesArray = () => {
		const datesArray: any[] = [];
		const {
			todaysDate,
			oneMonthAgo,
			threeMonthsAgo,
			sixMonthsAgo,
			oneYearAgo,
			yesterdayDate,
		} = getComparisonDates();

		datesArray.push({
			beginDate: 'inceptiondate',
			endDate: formatDateTime(todaysDate, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
		});

		datesArray.push({
			beginDate: formatDateTime(oneMonthAgo, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
			endDate: formatDateTime(todaysDate, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
		});

		datesArray.push({
			beginDate: formatDateTime(threeMonthsAgo, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
			endDate: formatDateTime(todaysDate, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
		});

		datesArray.push({
			beginDate: formatDateTime(sixMonthsAgo, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
			endDate: formatDateTime(todaysDate, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
		});

		datesArray.push({
			beginDate: formatDateTime(oneYearAgo, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
			endDate: formatDateTime(todaysDate, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
		});

		datesArray.push({
			beginDate: formatDateTime(yesterdayDate, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
			endDate: formatDateTime(todaysDate, {
				format: DATE_FORMATS.YEAR_MONTH_DATE,
			}),
		});
		return datesArray;
	};

	const getNewsData = async (xrefItems: IXrefResponseItem[]) => {
		const newsPromises = xrefItems?.map((item) => {
			const bridgeSymbol = `|${item?.exchange?.countryIso};${item?.symbol}|`;
			const newsInput = buildNewsInputs(bridgeSymbol);
			return getNews(newsInput);
		});

		const [newsResponses] = await Promise.all([Promise.all(newsPromises)]);

		const news = xrefItems.map((item, index) => {
			const newsResponse = newsResponses[index];
			if (newsResponse && newsResponse.data?.data?.items?.length > 0) {
				return { xid: item?.xids?.venue, hasRecentNews: true };
			}
		});

		return news;
	};

	const getXrefAndQuotesData = async (xids: number[]) => {
		const xrefResponses = await fetchXrefDataInChunks(xids);
		const [quoteResponse] = await Promise.all([getQuotes(xids)]);
		const xrefItems = xrefResponses.flatMap(
			(item) => item?.data?.data?.items || [],
		);
		const quoteItems = quoteResponse?.data?.data?.quotes;
		return { xrefItems, quoteItems };
	};

	const getNMonthsPerformanceData = (
		months: number,
		unrealizedGains?: IUnrealizedGain[],
	) => {
		const {
			todaysDate,
			oneMonthAgo,
			threeMonthsAgo,
			sixMonthsAgo,
			oneYearAgo,
		} = getComparisonDates();

		let beginDate: string;

		switch (months) {
			case 1:
				beginDate = oneMonthAgo;
				break;
			case 3:
				beginDate = threeMonthsAgo;
				break;
			case 6:
				beginDate = sixMonthsAgo;
				break;
			case 12:
				beginDate = oneYearAgo;
				break;
		}

		const filteredData = unrealizedGains?.filter(
			(gain) => gain.beginDate === beginDate && gain.endDate === todaysDate,
		);

		return filteredData?.[0]?.percent ?? TEXT_NULL_VALUE;
	};

	const getHoldings = async () => {
		setContainerState({ isLoading: true, noData: false });
		setHoldingsData({
			...holdingsData,
			name: '',
			id: '',
			holdings: [],
		});
		setAssetAllocationData(null);
		const sortingPreference = await getSortingInfo();
		const [holdingsResponse, transactionsResponse, holdingsAnalysisResponse] =
			await Promise.all([
				holdingInstance.getHoldings(),
				transactionInstance.getTransactions(),
				getPortfoliosInfo([data.id as string], getDatesArray()),
			]);

		if (checkIfAPIError(holdingsResponse)) {
			logAPIError();
		} else {
			const tempData = await getPortfolioHoldingsData(
				holdingsResponse,
				transactionsResponse,
				holdingsAnalysisResponse,
			);

			setHoldingNewsFlag(tempData, sortingPreference);
		}
	};

	const setHoldingNewsFlag = async (
		data: any,
		sortingPreference: IPortfolioSortingInfo,
	) => {
		const newsData = await getNewsData(data?.xrefItems);

		data?.dataObject?.holdings?.forEach((holding: any) => {
			const xrefItem = data?.xrefItems?.find(
				(xrefData: any) => xrefData?.xids?.venue === holding?.xid,
			);

			holding.hasRecentNews =
				newsData.find((news) => news?.xid == xrefItem?.xids?.venue)
					?.hasRecentNews ?? false;
		});

		sortPortfolioData(data?.dataObject, sortingPreference);
	};

	const getPortfolioHoldingsData = async (
		holdingsResponse: IGetHoldingsResponse,
		transactionsResponse: IGetTransactionsResponse,
		holdingsAnalysisResponse: void | IPortfolioAnalysisResponse,
	) => {
		const holdings = holdingsResponse?.data?.data?.items;
		if (!holdings?.length) {
			setContainerState({
				noData: true,
				isLoading: false,
			});
			return;
		}
		const transactions = transactionsResponse?.data?.data?.items;
		const userAnalysisResponse = holdingsAnalysisResponse?.data?.data;
		const containerAnalysisResponse =
			userAnalysisResponse?.containers?.items?.[0];
		const xids = holdings.map((e) => e.xid);
		const allocationObj: { [key: string]: any } = {};

		const { xrefItems, quoteItems } = await getXrefAndQuotesData(xids);

		const items = holdings.map((holding: any) => {
			const xrefItem = xrefItems?.find(
				(xrefData) => xrefData?.xids?.venue === holding?.xid,
			);
			const quoteItem = quoteItems.find(
				(quote) => quote?.data?.venueXid === holding?.xid,
			)?.data;

			const analysisHoldingItem =
				holdingsAnalysisResponse?.data?.data?.containers?.items?.[0]?.holdings?.items.find(
					(anlaysisHolidng: any) => holding.id === anlaysisHolidng.id,
				);
			// Holding level calculations
			const percentHolding = getPercentageHolding(
				containerAnalysisResponse,
				analysisHoldingItem,
			);
			const holdingTransactions = transactions.find(
				(transaction: any) => transaction.holdingId === holding.id,
			);
			const transactionRows = holdingTransactions?.transactions?.items.map(
				(transaction: any) => {
					// Transaction level calculations
					return getTransactionLevelData(
						transaction,
						quoteItem,
						analysisHoldingItem,
					);
				},
			);
			const unrealizedGain = analysisHoldingItem?.unrealizedGain;
			const unrealizedGainIndex = getUnrealizedGainIndex(
				unrealizedGain ?? [],
				holding.inceptionDate?.split('T')?.[0],
			);
			const ellipsis = '...';
			const holdingLevelRow: ITableHolding = {
				hasRecentNews: false,
				transactions: transactionRows,
				xrefData: xrefItem,
				symbol: xrefItem?.symbol ?? TEXT_NULL_VALUE,
				symbolName: xrefItem?.name ?? '',
				name: (
					<SymbolDetails
						venueXid={xrefItem?.xids?.venue ?? 0}
						displayName={xrefItem?.name ?? ''}
					/>
				),
				xid: holding.xid,
				entity: xrefItem?.xids?.entity,
				id: holding.id,
				insertDateTime: holding.insertDateTime,
				description: holding.description,
				quantity: holding.quantity,

				// Quotes
				last: analysisHoldingItem?.quote?.lastTrade?.last ?? 0,
				todaysDollarChange: getTodaysChange(
					PORTFOLIO_CONSTANT.TODAY_CHANGE,
					quoteItem,
				),
				todaysPercentChange: getTodaysChange(
					PORTFOLIO_CONSTANT.TODAY_PERCENT_CHANGE,
					quoteItem,
				),
				currentDollarValue: analysisHoldingItem?.value?.[0]?.value,
				percentOfPortfolio: percentHolding,
				gainLossSincePurchase: unrealizedGain?.[unrealizedGainIndex]?.gain,
				totalReturn: unrealizedGain?.[unrealizedGainIndex]?.percent ?? 0,

				// Performance
				oneMonthPercentPerf: getNMonthsPerformanceData(1, unrealizedGain),
				threeMonthPercentPerf: getNMonthsPerformanceData(3, unrealizedGain),
				sixMonthPercentPerf: getNMonthsPerformanceData(6, unrealizedGain),
				twelveMonthPercentPerf: getNMonthsPerformanceData(12, unrealizedGain),

				fiftyTwoWeekLowDollar: quoteItem?.price52Week.low ?? TEXT_NULL_VALUE,
				fiftyTwoWeekHighDollar: quoteItem?.price52Week.high ?? TEXT_NULL_VALUE,
				marketCapitalTNA: quoteItem?.marketCap ?? TEXT_NULL_VALUE,

				// Fundamentals Table
				divExpiryDate: ellipsis,
				divPayDate: ellipsis,
				divYield: ellipsis,
				epsTtm: ellipsis,
				peRatio: ellipsis,
			};

			//setAllocation Obj Pie chart
			setAllocationObj(
				allocationObj,
				quoteItem,
				percentHolding,
				holdingLevelRow,
				analysisHoldingItem,
			);

			return holdingLevelRow;
		});
		const allUnRealizedGainIndex = getUnrealizedGainIndex(
			userAnalysisResponse?.all?.unrealizedGain ?? [],
			data.inceptionDate ?? '',
		);
		const dataObject = getDataObject(
			items,
			userAnalysisResponse,
			allUnRealizedGainIndex,
		);
		massageAllocationData(allocationObj);
		setHoldingsData(dataObject);

		return {
			dataObject: dataObject,
			xrefItems: xrefItems,
		};
	};

	const getTodaysChange = (value: string, quoteItem: any) => {
		if (value === PORTFOLIO_CONSTANT.TODAY_CHANGE) {
			return quoteItem?.lastTrade?.change ?? 0;
		}
		if (value === PORTFOLIO_CONSTANT.TODAY_PERCENT_CHANGE) {
			return quoteItem?.changePercent?.today ?? 0;
		}
	};

	const getPercentageHolding = (
		containerAnalysisResponse: any,
		analysisHoldingItem: any,
	) => {
		return containerAnalysisResponse?.value?.[0]?.value &&
			containerAnalysisResponse?.value?.[0]?.value !== 0 &&
			analysisHoldingItem?.value?.[0]?.value
			? 100 *
					(analysisHoldingItem.value[0].value /
						containerAnalysisResponse.value[0].value)
			: TEXT_NULL_VALUE;
	};

	const getDataObject = (
		items: ITableHolding[],
		userAnalysisResponse: any,
		allUnRealizedGainIndex: number,
	) => {
		return {
			name: data?.name ?? '',
			id: data?.id ?? '',
			holdings: items,
			combinedTotals: 'Portfolio Totals',
			combinedCurrentDollarValue: userAnalysisResponse?.all?.value?.[0]?.value,
			combinedGainLossSincePurchase:
				userAnalysisResponse?.all?.unrealizedGain?.[allUnRealizedGainIndex]
					?.gain,
			combinedTotalReturn:
				userAnalysisResponse?.all?.unrealizedGain?.[allUnRealizedGainIndex]
					?.percent ?? 0,
		};
	};

	const getTransactionLevelData = (
		transaction: IGetTransactionItemResponse,
		quoteItem: any,
		analysisHoldingItem: any,
	) => {
		const valuePurchase =
			transaction.quantity * parseFloat(transaction.unitValue);
		const valueCurrent =
			transaction?.splitAdjustedQuantity *
			(analysisHoldingItem?.quote?.lastTrade?.last || 0);
		const gainLossSincePurchase =
			valueCurrent - valuePurchase - transaction.fees;
		const percentTransaction = analysisHoldingItem?.value?.[0].value
			? 100 * (valueCurrent / analysisHoldingItem?.value?.[0].value)
			: TEXT_NULL_VALUE;
		const totatReturn =
			valuePurchase !== 0
				? (100 * (valueCurrent - valuePurchase - transaction.fees)) /
					valuePurchase
				: TEXT_NULL_VALUE;
		return {
			id: transaction.id,
			insertDateTime: transaction.insertDateTime,
			quantity: transaction?.splitAdjustedQuantity,
			last: analysisHoldingItem?.quote?.lastTrade?.last ?? TEXT_NULL_VALUE,
			todaysDollarChange: quoteItem?.lastTrade?.change ?? TEXT_NULL_VALUE,
			todaysPercentChange: quoteItem?.changePercent?.today ?? TEXT_NULL_VALUE,
			currentDollarValue: valueCurrent,
			percentOfPortfolio: percentTransaction,
			gainLossSincePurchase: gainLossSincePurchase,
			totalReturn: totatReturn,
			oneMonthPercentPerf:
				quoteItem?.changePercent?.oneMonth ?? TEXT_NULL_VALUE,
			threeMonthPercentPerf:
				quoteItem?.changePercent?.threeMonth ?? TEXT_NULL_VALUE,
			sixMonthPercentPerf:
				quoteItem?.changePercent?.sixMonth ?? TEXT_NULL_VALUE,
			twelveMonthPercentPerf:
				quoteItem?.changePercent?.oneYear ?? TEXT_NULL_VALUE,
		};
	};

	const setAllocationObj = (
		allocationObj: { [key: string]: any },
		quoteItem: any,
		percentHolding: string | number,
		holdingLevelRow: ITableHolding,
		analysisHoldingItem: any,
	) => {
		const assetType = getStockAssetClass(quoteItem?.marketCap || '');
		if (!allocationObj[assetType]) {
			allocationObj[assetType] = {
				[PORTFOLIO_TRACKER_KEYS.GAIN]: holdingLevelRow?.gainLossSincePurchase,
				[PORTFOLIO_TRACKER_KEYS.PERCENT_PORTFOLIO]: percentHolding,
				totalCost: analysisHoldingItem?.cost?.[0]?.totalCostLong,
			};
		} else {
			allocationObj[assetType][PORTFOLIO_TRACKER_KEYS.PERCENT_PORTFOLIO] +=
				percentHolding;
			allocationObj[assetType][PORTFOLIO_TRACKER_KEYS.GAIN] +=
				holdingLevelRow?.gainLossSincePurchase;
			allocationObj[assetType]['totalCost'] +=
				analysisHoldingItem?.cost?.[0]?.totalCostLong;
		}
	};

	const massageAllocationData = (allocationObj: any) => {
		const newAllocations: IAssetAllocation = {
			data: [],
			colors: [],
			allocations: [],
		};

		if (allocationObj) {
			STOCKS_TYPE_COLOR_MAPPING.forEach((item: any) => {
				const obj = allocationObj[item.key];
				if (obj) {
					newAllocations.data.push(
						obj[PORTFOLIO_TRACKER_KEYS.PERCENT_PORTFOLIO],
					);
					newAllocations.colors.push(item.color);
					const returnValue =
						(obj[PORTFOLIO_TRACKER_KEYS.GAIN] / obj['totalCost']) * 100;
					newAllocations.allocations.push({
						[PORTFOLIO_TRACKER_KEYS.GAIN]: obj[PORTFOLIO_TRACKER_KEYS.GAIN],
						[PORTFOLIO_TRACKER_KEYS.PERCENT_PORTFOLIO]:
							obj[PORTFOLIO_TRACKER_KEYS.PERCENT_PORTFOLIO],
						[PORTFOLIO_TRACKER_KEYS.NAME]: item.key,
						[PORTFOLIO_TRACKER_KEYS.RETURN]: returnValue,
					});
				}
			});
		}
		setAssetAllocationData(newAllocations);
	};

	const getSortDirection = (colName: string) => {
		if (sortingInfo?.[activeSelectedTab]?.sortedColumn !== colName) {
			switch (activeSelectedTab) {
				case PORTFOLIO_TRACKER_TAB_NAMES.Quotes:
					return QUOTES_PORTFOLIO_TRACKER_HEADER.find((x) => x.key === colName)
						?.sortDir;
				case PORTFOLIO_TRACKER_TAB_NAMES.Performance:
					return PERFORMANCE_PORTFOLIO_TRACKER_HEADER.find(
						(x) => x.key === colName,
					)?.sortDir;
				case PORTFOLIO_TRACKER_TAB_NAMES.Fundamentals:
					return FUNDAMNETALS_PORTFOLIO_TRACKER_HEADER.find(
						(x) => x.key === colName,
					)?.sortDir;
			}
		}
		return sortingInfo?.[activeSelectedTab]?.sortDir === 'asc' ? 'desc' : 'asc';
	};

	const sortPortfolioData = (
		portfolioData?: IHoldingsWithTransactions,
		sortingPreference?: IPortfolioSortingInfo,
	) => {
		if (portfolioData) {
			const sortCol =
				sortingPreference?.[activeSelectedTab]?.sortedColumn ??
				sortingInfo?.[activeSelectedTab]?.sortedColumn;
			const sortDir =
				sortingPreference?.[activeSelectedTab]?.sortDir ??
				sortingInfo?.[activeSelectedTab]?.sortDir;
			const sortedData = [...portfolioData.holdings]?.sort(
				sortByKey(
					sortCol === 'name' ? 'symbolName' : (sortCol as string),
					sortDir as SORTING_DIRECTION,
				),
			);
			setHoldingsData({
				...portfolioData,
				holdings: sortedData,
			});
			setContainerState({ isLoading: false, noData: false });
		}
	};

	const sortDataHandler = (colName: string) => {
		const columnName =
			colName || (sortingInfo?.[activeSelectedTab]?.sortedColumn as string);
		const sortedInfo: ISortingInfo = {
			sortedColumn: columnName,
			sortDir: getSortDirection(columnName) as SORTING_DIRECTION,
		};
		const sortingPreference: IPortfolioSortingInfo = {
			...sortingInfo,
			[activeSelectedTab]: sortedInfo,
		};
		sortPortfolioData(holdingsData, {
			...sortingInfo,
			...sortingPreference,
		});
		setSortingInfo({
			...sortingInfo,
			...sortingPreference,
		});

		setUserPreferences(
			PORTFOLIO_TRACKER_PREFERENCE_KEY[activeSelectedTab],
			JSON.stringify(sortedInfo),
		);
	};

	const renderTabs = () => {
		return (
			<>
				<Tabs
					id={'portfolio-tracker-tabs'}
					ariaLabel={activeSelectedTab}
					activeTab={activeSelectedTab}
					onClick={(k: any) => {
						setActiveSelectedTab(k);
						setActiveTab(k);
						setIsExportData(false);
					}}
				>
					<div key="Quotes" data-label="Quotes"></div>
					<div key="Performance" data-label="Performance"></div>
					<div key="Fundamentals" data-label="Fundamentals"></div>
				</Tabs>
				{!containerState.noData && (
					<div className="export-btn-container">
						<a
							data-testid="export-btn-container"
							className="portfolio-export-button"
							onClick={() => setIsExportData(true)}
							tabIndex={0}
							onKeyDown={(e) =>
								handleEnterKeyPress(e, () => {
									setIsExportData(true);
								})
							}
						>
							<span>Export Data</span>
							<ArrowRightIcon color={'#2B6D9F'} height={11} width={11} />
						</a>
					</div>
				)}
			</>
		);
	};

	const isExportComplete = () => {
		setIsExportData(false);
	};

	const renderBody = () => {
		if (!data?.id) {
			return null;
		}
		switch (activeSelectedTab) {
			case PORTFOLIO_TRACKER_TAB_NAMES.Quotes:
				return (
					<PortfolioTrackerTable
						portfolioData={holdingsData}
						constants={QuotesPortfolioConstants}
						showTransactions
						activeSelectedTab={PORTFOLIO_TRACKER_TAB_NAMES.Quotes}
						isExportData={isExportData}
						isExportComplete={isExportComplete}
						sortingPreference={sortingInfo?.Quotes}
						sortHandler={sortDataHandler}
					/>
				);
			case PORTFOLIO_TRACKER_TAB_NAMES.Performance:
				return (
					<PortfolioTrackerTable
						portfolioData={holdingsData}
						constants={PerformancePortfolioConstants}
						showTransactions
						activeSelectedTab={PORTFOLIO_TRACKER_TAB_NAMES.Performance}
						isExportData={isExportData}
						isExportComplete={isExportComplete}
						sortingPreference={sortingInfo?.Performance}
						sortHandler={sortDataHandler}
					/>
				);
			case PORTFOLIO_TRACKER_TAB_NAMES.Fundamentals:
				return (
					<PortfolioTrackerTable
						portfolioData={holdingsData}
						constants={FundamentalsPortfolioConstants}
						showTransactions={false}
						activeSelectedTab={PORTFOLIO_TRACKER_TAB_NAMES.Fundamentals}
						isExportData={isExportData}
						isExportComplete={isExportComplete}
						loadDivAndRatiosData={loadDivAndRatiosData}
						setLoadDivAndRatiosData={setLoadDivAndRatiosData}
						sortingPreference={sortingInfo?.Fundamentals}
						sortHandler={sortDataHandler}
					/>
				);
		}
	};

	const getCurrentPortfolioValueHeader = () => {
		const value = formatNumber(
			holdingsData?.combinedCurrentDollarValue as string,
			{
				commaSeparated: true,
			},
		)?.value;
		return (
			<Heading
				content={`<div>
							<span>Current Portfolio Value: </span>
							<span>${value}</span>
						</div>`}
				type={'h3'}
				isHtmlContent
			/>
		);
	};

	const bindPieChart = () => {
		return (
			<div className="pie-chart-master">
				<div className="pie-chart-portfolio">
					{isMobile && getCurrentPortfolioValueHeader()}
					<PortfolioTrackerChart
						assetAllocationsData={assetAllocationDataMemo || undefined}
					/>
				</div>
				<div className="pie-chart-related-details">
					{!isMobile && getCurrentPortfolioValueHeader()}
					{isMobile && (
						<div className="hide-label">
							<LabelLink
								text={controls}
								className={'labelLink'}
								onClick={showLegend ? hideLegendHandler : showLegendHandler}
								onKeyDown={(e) =>
									handleEnterKeyPress(
										e,
										showLegend ? hideLegendHandler : showLegendHandler,
									)
								}
							></LabelLink>
						</div>
					)}
					{showLegend && (
						<div className="asset-type">
							{STOCKS_TYPE_COLOR_MAPPING.filter(
								(e) => e.isEnabled === true,
							)?.map((item: any) => {
								return (
									<div className="asset-container" key={`row_${item.key}`}>
										<div
											className={'asset-color-square'}
											style={{ background: `${item.color}` }}
										/>
										<Label text={item.key} size={'m'} />
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		);
	};

	const bindUpperView = () => {
		return <div className={'upper-view'}>{bindPieChart()}</div>;
	};

	const renderView = () => {
		if (containerState.isLoading) return <Loader />;
		else if (containerState.alert?.showAlert)
			return (
				<MessageBox
					content={containerState.alert?.content}
					state={containerState.alert?.state}
				/>
			);

		return (
			<>
				{bindUpperView()}
				<div data-testid="portfolio-lower-view" className={'lower-view'}>
					<div className="tab-headers"> {renderTabs()}</div>
					<div className="tab-container">{renderBody()}</div>
				</div>
			</>
		);
	};

	return <div id="portfolio-tracker-tabs-container">{renderView()}</div>;
};

export default React.memo(PortfolioTracker);
