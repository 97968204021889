/* eslint-disable indent */
import React from 'react';
import {
	E_DeviceType,
	TYPES_OF_ELEMENTS,
} from '../../../constants/appConstants';
import {
	EditIcon,
	NotesIcon,
	TrashIcon,
	DeleteIcon,
	IBasicIconProps,
	MinusIcon,
	PlusIcon,
	NewsIcon,
	PrinterIcon,
	DefaultArrowIcon,
	AscendingArrowIcon,
	DescendingArrowIcon,
} from '../../../assets/Icons';
import {
	Label,
	NumberLabel,
	LabelLink,
	Textbox,
	SymbolDetails,
} from '../../../@core-ui';
import { getDeviceType, handleEnterKeyPress } from '../../../utilities/utils';
import {
	STOCKS_TYPE as ASSET_TYPE,
	IHoldingsWithTransactions,
	PORTFOLIO_TRAKER_KEYS,
} from './portfolio-tracker/portfolio-tracker-constants';
import { TRANSACTIONS_KEYS } from './portfolio-constants';
import { ISortingInfo } from '../../../@core-ui/Table/table';
const isMobile = getDeviceType() === E_DeviceType.Mobile;

export const generateColValue = (
	item: { [key: string]: any },
	text: string,
	style?: any,
	numberDefaultValue?: any,
	sortingInfo?: ISortingInfo,
	sortDataHandler?: (
		colName: string,
		portfolioData?: IHoldingsWithTransactions,
	) => void,
) => {
	let node: React.ReactNode;
	switch (item.type) {
		case TYPES_OF_ELEMENTS.LABEL:
			node = (
				<Label
					text={text}
					size={item.size || 'm'}
					isBold={item.isBold}
					isGrey={item.isGrey}
					ellipsisEnabled={item.showEllipsis}
					style={style}
					isDarkGrey={item.isDarkGrey}
					className={item.className}
					fontType={item.fontType}
				/>
			);
			break;
		case TYPES_OF_ELEMENTS.LINK_LABEL:
			node = getNameNode(item, text);
			break;
		case TYPES_OF_ELEMENTS.SYMBOl_DETAILS_LABEL:
			node = (
				<SymbolDetails
					venueXid={item?.venueXid}
					displayName={text}
					isCompanyNameOrSymbol={true}
				/>
			);
			break;
		case TYPES_OF_ELEMENTS.NUMBER:
			node = (
				<NumberLabel
					value={text}
					isBold={item.isBold}
					numberConfig={{
						showColor: item.showColor,
						preFix: item.preFix,
						postFixValue: item.postFix,
						showPercent: item.showPercent,
						precision: item.precision,
						internationalCurrency: item.internationalCurrency,
						commaSeparated: item.commaSeparated,
						defaultValue: numberDefaultValue,
					}}
					style={style}
				/>
			);
			break;
		case TYPES_OF_ELEMENTS.INPUT:
			node = (
				<Textbox
					placeholder={item.placeholder}
					type={item.typeParam}
					min={item.min}
					max={item.max}
					name={item.key}
					value={item.key === TRANSACTIONS_KEYS.FEES ? text || item.min : text}
					onChange={item.onChange}
					data-key={item.dataKey}
					data-id={item.dataId}
					data-error={item.highlight}
				/>
			);
			break;
		case TYPES_OF_ELEMENTS.NOTES_ICON:
			node = getIconNode(NotesIcon, item);
			break;
		case TYPES_OF_ELEMENTS.DELETE_ICON:
			node = getIconNode(DeleteIcon, item);
			break;
		case TYPES_OF_ELEMENTS.EDIT_ICON:
			node = getIconNode(EditIcon, item);
			break;
		case TYPES_OF_ELEMENTS.TRASH_ICON:
			node = getIconNode(TrashIcon, item);
			break;
		case TYPES_OF_ELEMENTS.PRINT_ICON:
			node = getIconNode(PrinterIcon, item);
			break;
		case TYPES_OF_ELEMENTS.HEADER:
			node = (
				<LabelLink
					text={text}
					size={item.size || 's'}
					isBold={item.isBold}
					ellipsisEnabled={item.showEllipsis}
					className={item.className}
					icon={getArrow(item.key, sortingInfo)}
					iconDir={item.key === PORTFOLIO_TRAKER_KEYS.NAME ? 'right' : 'left'}
					onClick={
						sortDataHandler ? () => sortDataHandler(item.key) : undefined
					}
					onKeyDown={
						sortDataHandler ? () => sortDataHandler(item.key) : undefined
					}
				/>
			);
			break;
		default:
			node = <span>{text}</span>;
	}
	return node;
};

export const bindData = (
	TABLE_HEADERS: any,
	type?: string,
	className?: string,
	text?: string | null,
	style?: any,
	nodeStyle?: any,
	sortingInfo?: ISortingInfo,
	sortDataHandler?: (
		colName: string,
		portfolioData?: IHoldingsWithTransactions,
	) => void,
) => {
	return (
		<div className={`${className}`} style={style}>
			{TABLE_HEADERS?.map((item: any) => {
				return (
					<React.Fragment key={`${type}_${item.label}`}>
						{generateColValue(
							item,
							text ?? item.label,
							nodeStyle,
							null,
							sortingInfo,
							sortDataHandler,
						)}
					</React.Fragment>
				);
			})}
		</div>
	);
};

const getIconNode = (
	Icon: React.FC<IBasicIconProps>,
	item: { [key: string]: any },
) => {
	return (
		<div>
			<button
				className={item.className}
				data-key={item.dataKey}
				data-action={item.action}
				data-id={item.dataId}
				onClick={item.onClick}
				data-master-action={item.masterAction ? true : false}
			>
				<Icon width={item.width} height={item.height} color={item.color} />
				{item.text ? <Label text={item.text} size={'m'} /> : null}
			</button>
		</div>
	);
};

const getNameNode = (item: { [key: string]: any }, text: any) => {
	const symbolDetails = {
		xid: text?.props?.venueXid,
		name: text?.props?.displayName,
	};
	return (
		<span className="name-col">
			<span className="expandable-symbol">
				{item.hasTransactions && (
					<span className="expand-icon" onClick={item.onExpandIconClick}>
						{item.isExpanded ? (
							<MinusIcon color={'#2B6D9F'} />
						) : (
							<PlusIcon color={'#2B6D9F'} />
						)}
					</span>
				)}
				{React.isValidElement(text) ? (
					<span className={`${item.hasRecentNews && 'company-name-column'}`}>
						<SymbolDetails
							venueXid={symbolDetails?.xid || 0}
							displayName={symbolDetails?.name || ''}
							isClickable
							isCompanyNameOrSymbol
						/>
					</span>
				) : (
					<LabelLink
						text={text}
						size={item.size || 'm'}
						isBold={item.isBold}
						ellipsisEnabled={item.ellipsisEnabled}
						onClick={item.onClick}
						data-key={item.dataKey}
						data-action={item.action}
						data-master-action={item.masterAction ? true : false}
						onKeyDown={(e) => handleEnterKeyPress(e, item.onClick)}
						isHideTitle={item.isHideTitle}
					/>
				)}
			</span>
			{item.hasRecentNews && (
				<span className="notes-icon" onClick={item.onNotesIconClick}>
					<NewsIcon height={22} width={20} />
				</span>
			)}
		</span>
	);
};

export const checkIfAPIError = (response: any) => {
	return response?.error || response?.data?.data?.errors?.length;
};

export const getStockAssetClass = (marketCap: number | string) => {
	let assetClass = '';
	if (marketCap > 8000000000) {
		assetClass = ASSET_TYPE.LARGE_CAP_STOCKS;
	} else if (marketCap >= 2000000000) {
		assetClass = ASSET_TYPE.MID_CAP_STOCKS;
	} else if (marketCap > 0) {
		assetClass = ASSET_TYPE.SMALL_CAP_STOCKS;
	}
	return assetClass;
};

const getArrow = (text: string, sortingInfo: ISortingInfo | undefined) => {
	let icon;
	if (isMobile) return icon;
	if (text === sortingInfo?.sortedColumn) {
		icon =
			sortingInfo?.sortDir === 'asc' ? (
				<AscendingArrowIcon />
			) : (
				<DescendingArrowIcon />
			);
	} else {
		icon = <DefaultArrowIcon />;
	}
	return icon;
};
